import React from 'react'
import Ghost from '../../assets/images/mint/ghost.png'
import RasniaLogo from '../../assets/images/parallax_top.png';
import MintPlaceHolder from '../../assets/images/mint/mintplaceholder.jpg'
import ConnectWalletImage from '../../assets/images/mint/button_connect_down.png';
import Divider from "../Divider/Divider"

import { Link } from "react-router-dom";
import TimerMini from '../Timer/TimerMini';
function MintPage() {
  return (
    <div className="mint">
    <rfox-wallet-connected disabled="false" loading="false" id="mintWallet" wallet-address="" wallet-balance="">
    </rfox-wallet-connected>

          <div className='grid lg:grid-cols-2 place-items-center sm:grid-cols-1 gap-11'>
          <div className='ghost_pos ml-[150px] sm:ml-0'>
          <Link to="/" >
          <img src={RasniaLogo} alt='' className='ghost_logo' />
          </Link>
              <img src={Ghost }alt=''  className='ghost_bg' />
          </div>
          <div className='lg:relative lg:top-[-80px] md:relative sm:absolute sm:mt-[60px] w-[600px]'>
              <div className='w-[400px] sm:ml-[100px]'>
              <p className='white_list_mint_header mb-10'>WhiteList Mint</p>
              <div className='grid grid-cols-2  mt-[-50px] object-fit'>
                <div>
                <div className='flex flex-wrap justify-start'>
                  <p className='font-quatror text-white text-[16px]'>&nbsp;</p>
                    <TimerMini />
                  </div>

                </div>

                <div>
                  <div className='flex flex-wrap justify-end'>
                  <rfox-supply collection-address="0xece7F79289C24969E2b900C9DD90F1EfD5460567"
                  collection-id="0x8C9Ca8eae1Bf7149BC4380f0050aE8d7910D28af" contract-type="whitelist_721"
                  network-name="rinkeby" chain-id="4" dev="true" id="mintSupply">
                  </rfox-supply>  
                  <p className='font-quatror text-white text-[16px]'>&nbsp;minted</p>
                  </div>
                
                </div>

              </div>
                  <img src={MintPlaceHolder} alt=''  className='object-fit'/>
                  
                  <p className='white_list_mint_text mt-2 mb-2'>0xece7F79289C24969E2b900C9DD90F1EfD5460567 </p>
                  <rfox-drop-kit id="rinkebyPanel" collection-address="0xece7F79289C24969E2b900C9DD90F1EfD5460567"
                  collection-id="0x8C9Ca8eae1Bf7149BC4380f0050aE8d7910D28af" contract-type="whitelist_721"
                  whitelist-url="undefined" network-name="rinkeby" chain-id="4" wallet-text="" mint-text="Mint"
                  minting-text="Minting" sold-out-text="Sold Out" dev="true">
                    </rfox-drop-kit>
               </div>
         
             
              
          </div>
          </div>
          <div className='lg:block md:block xl:block 2xl:block sm:hidden'>
          <Divider/>
          </div>
        
          
    </div>
  )
}

export default MintPage