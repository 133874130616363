import React from 'react'
import SocialLink from "../assets/social.json"
function Footer() {
    const currentYear = (new Date().getFullYear())
    const yearTxt = currentYear === 2022 ? "2022" : "2022 - "+currentYear
    
    
  return (
 
  <div className='bg-system-color place-content-center  flex flex-wrap h-[150px] w-full'>
        
        <div className='flex items-center'>
        <img src={require('../assets/images/rnf_small.png')}  className='w-auto h-12 md:mr-10'/>
          <div className='block sm:hidden md:block'>
          <ul className="items-center justify-center space-y-8  
                                       sm:justify-center 
                                       sm:inline-block
                                       sm:space-y-5  
                                       md:flex md:space-x-6 md:space-y-0  ">
                            <li className="text-white font-quatrob text-[24px]  hover:text-yellow ">
                                <a href="#thegame" className="text-center">GAME</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#thelore">LORE</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#characters">CHARACTERS</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#faqs">FAQ</a>
                            </li>
                            <li className="text-white hover:text-indigo-200">
                            <a href={SocialLink.facebook}>
                                    <img src={require('../assets/images/facebook.png')} />
                                </a>
                            </li>
                            <li className="text-white hover:text-indigo-200">
                                <a href={SocialLink.twitter}>
                                    <img src={require('../assets/images/twitter.png')} />
                                </a>
                            </li>
               </ul>
          </div>
        </div>
  </div>

  )
}

export default Footer