import React from 'react'

function HeaderImage({imageSource,dividerStyle,imageStyle}) {
  return (
     <div className={dividerStyle}>
     <img src={imageSource} className={imageStyle} alt=''/>
     </div>
  )
}

export default HeaderImage