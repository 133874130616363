import { useState } from "react";
import SocialLink from "../assets/social.json"
export default function NavBar() {
    const [navbar, setNavbar] = useState(false);
    return (
        <header>

        <nav className="w-full bg-system-color ">
            <div className="justify-center px-4 mx-auto w-full md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between snap-center py-3 md:py-5 md:block">
                        <a href="#">
                           <img src={require('../assets/images/rnf_small.png')}  className='w-auto h-12 md:mr-10'/>
                        </a>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-yellow rounded-md outline-none"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 text-yellow"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6 text-yellow"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                   
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`
                        flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 
                        ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center justify-center space-y-8  
                                       sm:justify-center 
                                       sm:inline-block
                                       sm:space-y-5  
                                       md:flex md:space-x-6 md:space-y-0  ">
                            <li className="text-white font-quatrob text-[24px]  hover:text-yellow ">
                                <a href="#thegame" className="text-center">GAME</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#thelore">LORE</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#characters">CHARACTERS</a>
                            </li>
                            <li className="text-white font-quatrob text-[24px] hover:text-yellow">
                                <a href="#faqs">FAQ</a>
                            </li>
                            <li className="text-white hover:text-indigo-200">
                            <a href={SocialLink.facebook}>
                            <img src={require('../assets/images/facebook.png')} />
                             </a>
                            </li>
                            <li className="text-white hover:text-indigo-200">
                                <a href={SocialLink.twitter}>
                                    <img src={require('../assets/images/twitter.png')}/>
                                </a>
                            </li>
                        </ul>


                    </div>
                </div>

            </div>
        </nav>
        </header>
    
    );
}