import React,{useState} from 'react'
import HeadDenizen from "../assets/images/game_header_denizen.png"
import HeadDenizenMobile from "../assets/images/mobile_header_denizen.png"
/*Character*/
import Hunter from "../assets/images/1_den_Hunter.png"
import Witch from "../assets/images/2_den_Witch.png"
import Healer from "../assets/images/3_den_Healer.png"
import Gargoyle from "../assets/images/4_den_Gargoyle.png"
import Konchima from "../assets/images/5_den_Konchima.png"
/* Icons */
import IconHunter from "../assets/images/icon_Hunter.png"
import IconWitch from "../assets/images/icon_Witch.png"
import IconHealer from "../assets/images/icon_Healer.png"
import IconGargoyle from "../assets/images/icon_Gargoyle.png"
import IconKonchima from "../assets/images/icon_Konchima.png"
import Divider from './Divider'

import HeaderImage from './HeaderImage'

function Denizen() {
    const [champion, setChampion] = useState('hunter');
    const [counter,setCount] = useState(0);

    function Count(){
      setCount(counter + 1)
      if(counter ==4){
        setCount(0);
      }
    }

 


    
    function toogleCharacter(character){
        if(character==='hunter'){
          setChampion('hunter')
        }
   
        if(character==='witch'){
           setChampion('witch')
         }
   
       if(character==='healer'){
           setChampion('healer')
         }
   
         if(character==='gargoyle'){
           setChampion('gargoyle')
         }
   
         if(character==='konchima'){
           setChampion('konchima')
         }
         console.log('your champion selected is ' + character)
     }
  return (
    <section  id="characters">
    <div className='bg-character-bg bg-contain bg-center bg-no-repeat h-auto w-full sm:hidden md:block'>
      <HeaderImage 
      imageSource={HeadDenizenMobile} 
      dividerStyle='w-full'
      imageStyle=' mt-[100px] mx:0 md:mx-auto lg:mx-auto xl:ml-[170px]'
      />
      <div className='p-0'>
     
         <div className=' grid grid-cols-2 p-20'>
            <div className='col-span-4 '>
            {(() => {
                switch (champion) {
                case 'hunter':
                    return  <img src={Hunter}  alt='' className=''/>;
                case 'witch':
                    return  <img src={Witch} alt='' className=''/>;
                case 'healer':
                    return  <img src={Healer} alt='' className=''/>;
                case 'gargoyle':
                    return  <img src={Gargoyle} alt='' className=''/>;
                case 'konchima':
                      return  <img src={Konchima} alt='' className=''/>;
                default:
                }
            })()}
    
            </div>

            <div className='absolute  right-5 mt-20
               sm:hidden
               md:block md:mt-[100px]
               lg:right-[100px] lg:mt-[100px]
               xl:right-[170px] xl:mt-[200px]
               xl-2:right-[150px] xl-2:mt-[280px]
               2xl:right-[200px] 2xl:mt-[300px]
               2xxl:right-[250px] 2xxl:mt-[300px]
               '>
                <div  className="flex flex-wrap cursor-pointer mb-2 2xxl:mb-5" onClick={ ()=> toogleCharacter('hunter') }>
                <img src={IconHunter} alt=''/>
                <p className='font-quatrob text-[25px] text-white hover:text-yellow 2xxl:text-[32px] '>HUNTER</p>
                </div>

                <div  className="flex flex-wrap cursor-pointer mb-2 2xxl:mb-5" onClick={ ()=> toogleCharacter('witch') }>
                <img src={IconWitch} alt=''/>
                <p className='font-quatrob text-[25px] text-white hover:text-yellow 2xxl:text-[32px]'>WITCH</p>
                </div>

                <div  className="flex flex-wrap cursor-pointer mb-2 2xxl:mb-5" onClick={ ()=> toogleCharacter('healer') }>
                <img src={IconHealer} alt=''/>
                <p className='font-quatrob text-[25px] text-white hover:text-yellow 2xxl:text-[32px]'>HEALER</p>
                </div>

                <div  className="flex flex-wrap cursor-pointer mb-2 2xxl:mb-5" onClick={ ()=> toogleCharacter('gargoyle') }>
                <img src={IconGargoyle} alt=''/>
                <p className='font-quatrob text-[25px] text-white hover:text-yellow 2xxl:text-[32px]'>GARGOYLE</p>
                </div>

                <div  className="flex flex-wrap cursor-pointer mb-2 2xxl:mb-5" onClick={ ()=> toogleCharacter('konchima') }>
                <img src={IconKonchima} alt=''/>
                <p className='font-quatrob text-[25px] text-white hover:text-yellow 2xxl:text-[32px]'>KONCHIMA</p>
                </div>

            </div>
        </div>
    
      </div>

   
   

    </div>


    <div className='bg-character-bg bg-contain bg-center bg-no-repeat h-auto w-full md:hidden'>
    <img src={HeadDenizen}  className='mb-10 p-20 py-0 sm:mx-auto md:mx-auto lg:mx-0' alt=''/>
 

    <div className=' p-20 mx-auto py-0 '>
    <div  className="flex flex-wrap ">
    {(() => {
                switch (counter) {
                case 0:
                    return  <img src={IconHunter}  alt='' className=''/>;
                case 1:
                    return  <img src={IconWitch} alt='' className=''/>;
                case 2:
                    return  <img src={IconHealer} alt='' className=''/>;
                case 3:
                    return  <img src={IconGargoyle} alt='' className=''/>;

                case 4:
                      return  <img src={IconKonchima} alt='' className=''/>;
                       
                default:
             
                }
      })()}

      {(() => {
                      switch (counter) {
                      case 0:
                          return      <p className='font-quatrob text-[20px] text-white mt-1'>HUNTER</p>;
                      case 1:
                          return      <p className='font-quatrob text-[20px] text-white mt-1'>WITCH</p>;
                      case 2:
                          return      <p className='font-quatrob text-[20px] text-white mt-1'>HEALER</p>;
                      case 3:
                          return      <p className='font-quatrob text-[20px] text-white mt-1'>GARGOYLE</p>;
                      case 4:
                          return      <p className='font-quatrob text-[20px] text-white mt-1'>KONCHIMA</p>;
                            
                      default:
                  
                      }
            })()}

    </div>

    </div>
    <div className=' cursor-pointer' onClick={ ()=> Count() }>
    {(() => {
                switch (counter) {
                case 0:
                    return  <img src={Hunter}  alt='' className=''/>;
                case 1:
                    return  <img src={Witch} alt='' className=''/>;
                case 2:
                    return  <img src={Healer} alt='' className=''/>;
                case 3:
                    return  <img src={Gargoyle} alt='' className=''/>;

                case 4:
                        return  <img src={Konchima} alt='' className=''/>;
                       
                default:
             
                }
            })()}
    
    </div>

    
    <p className='font-quatror text-[12px] relative text-white text-center animate-pulse animate-bounce  '>TAP THE CHARACTER </p>;
   
    </div>
    </section>
   
  )
}

export default Denizen