import React from 'react'
import BlueBullet from "../assets/images/bullet_blue.png"
import OrangeBullet from "../assets/images/bullet_orange.png"

function List({color,text,hasUrl, urlString}) {
  return (
    <div className='flex py-1'>
        {
            color === 'red' ? <img src={BlueBullet} className='ml-0 h-[31px] w-[31px]  mt-[13px] sm:mt-0'/> : 
            <img src={OrangeBullet} className='ml-[100px] h-[31px] w-[31px] top-0 sm:ml-0 sm:top-1 md:ml-[100px]'/>
        }
        
        <p className='font-quatror text-game-text-color text-[26px] ml-2  mt-[-6px] md:text-[30px] sm:text-[18px] '>{text}</p> 

       
    </div>
  )
}
export default List