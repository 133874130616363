import './App.css';
import React, { useEffect }  from 'react';

import Navbar from './components/navbar';
import Home from './Section/Home';
import TheGame from './components/TheGame';
import Lore from './components/Lore';
import Denizen from './components/Denizen';
import Faqs from './components/Faqs';
import Footer from './components/Footer';
function Homepage() {

 

  return (
    <>
    <div className='App'>
    <React.StrictMode>
    <Navbar />
    <Home/>
    <TheGame/>
    <Lore />
    <Denizen />
    <Faqs />
     <Footer />
    </React.StrictMode>
    </div>
       
    </>
  )
}

export default Homepage