import React, { useState, useEffect } from "react";
import TimerTopImage from "../assets/images/parallax_top.png";
import { Link } from "react-router-dom";
import TimerConfig from "../assets/timer.json"
import ReactPlayer from 'react-player'
import VideoURL from "../assets/video.json"
function Home() {
  const [isMint, setMint] = useState(false)
  const [expiryTime, setExpiryTime] = useState(TimerConfig.expiration);
  const [isExpired,setExpired] = useState(false)
  const [isPlaying,setPlaying] = useState(false)
  const [countdownTime, setCountdownTime]= useState(
      {
          countdownDays:'',
          countdownHours:'',
          countdownlMinutes:'',
          countdownSeconds:''
      }
  );
   const countdownTimer=()=>{
   
         const timeInterval= setInterval(() => {
         const countdownDateTime = new Date(expiryTime).getTime(); 
         const currentTime = new Date().getTime();
         const remainingDayTime = countdownDateTime - currentTime;
         const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
         const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
         const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
    
         const runningCountdownTime={
            countdownDays: totalDays,
            countdownHours: totalHours,
            countdownMinutes: totalMinutes,
            countdownSeconds: totalSeconds
         }
      
         setCountdownTime(runningCountdownTime);
    
         if (remainingDayTime < 0) {
            clearInterval(timeInterval);
            setExpiryTime(false);
            setExpired(true)
           }
    
        }, 1000);
   }
    
   useEffect(() => {
       countdownTimer();
   });

   function tooglePlayer(){
    setPlaying(!isPlaying)
   }
  return (

    <>
    <div className='
    bg-hero-bg 
    bg-cover
    sm:bg-hero-bg-mobile 
    sm:h-[300px]
    sm:bg-cover
    sm:bg-center
    md:bg-hero-bg-mobile
    md:bg-center
    md:h-[500px]
    lg:bg-hero-bg lg:h-[700px]
    xl:bg-hero-bg  xl:h-[700px]
    xl-2:bg-hero-bg xl-2:h-[700px]
    2xl:bg-hero-bg 2xl:h-[700px]
    2xxl:bg-hero-bg 
    2xxl:h-[750px]
    h-screen w-full bg-no-repeat p-20 m-0 
    '>
      <div className="block sm:hidden md:hidden lg:block 2xl:block">
        <div className="grid grid-cols-2">
           
          <div className="trailer_desktop w-[500px] h-[450px]! ">
                <div className="justify-items-center
                ml-[150px] mt-[150px]
                lg:w-[450px] lg:h-[450px] lg:ml-[-50px]
                xl:ml-[10px]   xl:mt-[170px]
                xl-2:ml-[50px]
                2xxl:mt-[180px]  2xxl:w-[800px] 2xxl:h-[600px]
                " >
                <ReactPlayer 
                className='react-player'
                playing={isPlaying}
                url={VideoURL.video_url} 
                height='70%'
                width='100%'
                style={{visibility: isPlaying ? 'visible' : 'hidden'}}
                />
                <div className='cursor-pointer w-auto' onClick={()=> tooglePlayer() }>
                {
                isPlaying !== true ? <p className='font-quatrob text-yellow text-center text-[32px]  mb-5 hover:text-white'>Play Trailer</p> :
                <p className='font-quatrob text-yellow text-[32px]   text-center  mb-5 hover:text-white'>Stop Trailer</p>
              }
              </div>
          </div>
            
            
          </div>



          
          <div>

          <div className='timer_desktop opacity-75 h-[600px] w-[600px] 
          lg:w-[470px]  lg:h-[300px]
          2xxl:h-[600px] 2xxl:w-[600px] 
          '>
          <div className='h-[50px]'/>
          <div className=" bg-none w-auto ml-20">
          <img src={TimerTopImage} className='mx-auto ml-auto mr-auto' />
              <div className="bg-system-color">
              <p className='font-quatror text-red text-[36px] text-center  mx-auto lg:text-[22px] lg:mt-2'>Mint your RASNIA:FEAR NFT</p>
              <p className='font-quatror text-white text-[30px] text-center mt-2 mb-2 mx-auto  lg:text-[22px] lg:mt-2'>Drop starts September 27, 8AM UTC</p>
              <div className='grid grid-cols-4'>
                        <div className="days">
                            <p className="font-quatror text-red text-[16px] text-center md:text-[20px]">days</p>
                            <p className="font-quatrob text-yellow text-[32px] text-center md:text-[75px]">{countdownTime.countdownDays}</p>
                        </div>
                        <div className="hour">
                            <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">hours</p>
                            <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownHours}</p>
                        </div>
                        <div className="minutes">
                            <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">min</p>
                            <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownMinutes}</p>
                        </div>
                        <div className="seconds ">
                            <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">sec</p>
                            <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownSeconds}</p>
                        </div>
              </div>
              <div className='bg-dark-red cursor-pointer flex justify-center  w-full mx-auto h-[90px] p-5  sm:w-[250px] sm:h-[60px] sm:mt-5 md:w-full md:h-[90px]'>
             {
                isExpired !== false?  
                <Link to="mint" className='font-quatror text-white text-[30px] text-center '>{TimerConfig.button_message_expired}</Link>
                :  
                <Link to="mint" className='font-quatror text-white text-[30px] text-center sm:mt-[-5px] sm:text-[20px] md:text-[40px] md:p-0'>{TimerConfig.button_message}</Link>
              }
              </div>   
          </div>
       
               
     
          </div>
         
         
        
            </div>
            </div>


        </div>
      </div>  
    
    </div>
    
    <div className='trailer block  lg:hidden '>
    <div className="justify-items-center w-auto h-auto p-10" >
            <ReactPlayer 
            className='react-player'
            playing={isPlaying}
            url={VideoURL.video_url} 
            style={{opacity: isPlaying ? '0.9' : '0.1' }}
            controls={true}
            width='100%'
            height='200%'    
            />
            <div className='cursor-pointer w-auto' onClick={()=> tooglePlayer() }>
            {
             isPlaying !== true ? <p className='font-quatrob text-yellow text-center text-[32px]  mb-5 hover:text-white'>Play Trailer</p> :
             <p className='font-quatrob text-yellow text-[32px]   text-center  mb-5 hover:text-white'>Stop Trailer</p>
           }
           </div>
          </div>
    </div>

    <div className='mobile_timer lg:hidden'>
     <div className=' bg-system-color opacity-75 h-[500px] md:h-auto w-full  mx-auto'>
           <div className='h-[50px]'/>
           <img src={TimerTopImage} className='mx-auto' />
          <p className='font-quatror text-red text-[45px] text-center  mx-auto sm:text-[15px] sm:mt-10 md:text-[50px]'>Mint your RASNIA:FEAR NFT</p>
          <p className='font-quatror text-white text-[30px] text-center mt-10 mb-10 mx-auto sm:text-[15px] md:text-[50px]'>Drop starts September 27, 8AM UTC</p>
          <div>
          <div className='grid grid-cols-4'>
                <div className="days">
                    <p className="font-quatror text-red text-[16px] text-center md:text-[20px]">days</p>
                    <p className="font-quatrob text-yellow text-[32px] text-center md:text-[75px]">{countdownTime.countdownDays}</p>
                </div>
                <div className="hour">
                    <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">hours</p>
                    <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownHours}</p>
                </div>
                <div className="minutes">
                    <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">min</p>
                    <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownMinutes}</p>
                </div>
                <div className="seconds ">
                    <p className="font-quatror text-red text-[16px] text-center  md:text-[20px]">sec</p>
                    <p className="font-quatrob text-yellow text-[32px] text-center  md:text-[75px]">{countdownTime.countdownSeconds}</p>
                </div>
         </div>
             <div className='bg-dark-red cursor-pointer flex justify-center  w-full mx-auto h-[90px] p-5  sm:w-[250px] sm:h-[60px] sm:mt-5 md:w-full md:h-[90px]'>
             {
                isExpired !== false?  
                <Link to="mint" className='font-quatror text-white text-[30px] text-center '>{TimerConfig.button_message_expired}</Link>
                :  
                <Link to="mint" className='font-quatror text-white text-[30px] text-center sm:mt-[-5px] sm:text-[20px] md:text-[40px] md:p-0'>{TimerConfig.button_message}</Link>
              }
            </div>
          </div>
      </div>
    </div>

    </>
  )
}

export default Home