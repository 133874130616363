import React,{ useState} from 'react'
import TheGameImage from "../assets/images/game_header.png"
import TheGameImageMobile from "../assets/images/mobile_header_game.png"
import GameSrc1 from "../assets/images/game_thumb1.png";
import GameSrc2 from "../assets/images/game_thumb2.png";
import GameSrc3 from "../assets/images/game_thumb3.png";
import GameSrc4 from "../assets/images/game_thumb4.png";
import Divider from './Divider'
import BG1 from "../assets/images/ss1.jpg"
import BG2 from "../assets/images/ss2.jpg"
import BG3 from "../assets/images/ss3.jpg"
import BG4 from "../assets/images/ss4.jpg"
/* Lightbox 2022 */
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

/* Custom Style for List and Bullets */
import "../components/TheGame.css"
import List from './List';

function TheGame() {
    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = useState(0)
    function toogleLightbox() {
      setOpen(!open);
    }
  return (
    <section id='thegame'>
          <Divider />
          <div className=' bg-none h-auto w-full'>
            <div className='the_game_logo mb-10'>
              <img src={TheGameImageMobile}  
              className='
               mx-auto 
               xl:mx-0  xl:ml-[80px] xl:mt-[100px]
               2xl:mx-0  2xl:ml-[80px] 2xl:mt-[100px]
              ' alt=''/>
            </div>

            

          
          <div className='p-20 sm:p-0 md:p-0 xl:p-20 lg:p-20 2xl:p-20 2xxl:p-20'>

         
           <div className='m-0 sm:m-5 md:m-20 lg:m-0  xl-2:md-5 '>
              
           <div className='content-center object-center mt-[-50px] sm:mt-0 lg:mt-0'>
           <div className='p-20 sm:p-1 lg:p-20 xl:p-20 2xl:p-20 2xxl:p-32 2xxl:mt-[-100px]'>
           <div className="grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-col-4 lg:grid-cols-4 ">
               <img src={GameSrc1} alt=''  className='img_thumb cursor-pointer pointer-events-auto w-[550px] h-[350px] sm:h-auto lg:h-auto  ' onClick={ ()=> { setIndex(0);toogleLightbox() }}/>
               <img src={GameSrc2} alt='' className='img_thumb cursor-pointer pointer-events-auto w-[550px] h-[350px]  sm:h-auto lg:h-auto' onClick={ ()=> {  setIndex(1);toogleLightbox() }}/>
               <img src={GameSrc3} alt='' className='img_thumb cursor-pointer pointer-events-auto w-[550px] h-[350px]  sm:h-auto lg:h-auto' onClick={ ()=> {  setIndex(2);toogleLightbox() }}/>
               <img src={GameSrc4} alt='' className='img_thumb cursor-pointer pointer-events-auto w-[550px] h-[350px]  sm:h-auto lg:h-auto' onClick={ ()=> {  setIndex(3);toogleLightbox() }}/>
           </div>
           <div className='w-full mt-[100px] ml-[10px] lg:mt-[100px] sm:mt-[50px]'>
               <List color={'red'} text={'VR blockchain game, playable exclusively through the RFOX VALT app on Oculus Quest 2'}/>
               <List color={'red'} text={'Single player walkthrough experience available to all. Bring a change of underwear.'}/>
               <List color={'red'}  text={'Multiplayer experience available to RASNIA:FEAR NFT holders only'}/>
               <List color={'blue'}  text={'Find the treasure. NFTs, tokens and game items hidden within the castle.'}/>
               <List color={'blue'} text={'Escape before the castle and other players steal your loot..'}/>
               <a href='#' className='hover:text-white'><List color={'red'} text={'Follow us on twitter for launch dates and the latest information.'}/></a>
           </div>  
           </div>

           <div className='hidden'>
                   <Lightbox
                       open={open}
                       index={index}
                       close={() => toogleLightbox() }
                       slides={[
                       { src: BG1 },
                       { src: BG2 },
                       { src: BG3 },
                       { src: BG4 },
                       ]}
                   />
           </div>
 

           </div>


           </div>

           
          </div>


  
     

          </div>
          
    </section>
  )
}

export default TheGame