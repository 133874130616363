import React from 'react'
import TheLoreImageMobile from "../assets/images/mobile_header_lore.png"
import TheSign from "../assets/images/the.png"
import Divider from './Divider'
import HeaderImage from './HeaderImage'
function Lore() {
  return (
   
    <section id='thelore'>
       <Divider/>
      <div className='bg-the-lore-bg bg-cover bg-center h-auto w-full 
      md:bg-contain md:bg-no-repeat md:bg-top
      '>
        <HeaderImage 
        imageSource={TheLoreImageMobile} 
        dividerStyle='w-full'
        imageStyle=' mt-[100px] mx:0 
        sm:mx-auto
        md:mx-auto 
        lg:mx-auto
        xl:mx-0 xl:ml-[60px]
        xl-2:mx-0 xl-2:ml-[60px]
        2xxl:ml-[70px]
        '
        />
      <div className='p-20
            sm:p-0 sm:w-full sm:ml-0 
            md:p-20 md:w-auto md:mx-auto
            lg:p-20 lg:w-full lg:mx-auto
            xl:p-8 xl:w-[650px]  xl:ml-[195px]
            xl-2:p-22 xl-2:w-[750px]  xl-2:ml-[200px]
            2xl:p-22 2xl:w-[750px]  2xl:ml-[300px]
            2xxl:w-[750px] 2xxl:p-1 2xxl:ml-[350px]
            '>
           
            <div className='flex ml-[50px]
            sm:p-8  sm:ml-0 
            md:p-8  
            lg:p-0 lg:ml-0
            xl:p-5 xl:ml-0
            2xxl:p-4 2xxl:ml-[50px]'>
            <img src={TheSign} className='h-[108px] w-[81px]'/>
            <p className='font-quatror text-[31px] text-game-text-color text-justify sm:text-[16px] md:text-[30px]'  >he lands of Rasnia were once the most fertile of all the known world. The Rasnians were a peaceful group of people who lived off the land which was precious in resources. </p>
            </div>

            <div className='w-full px-0 ml-[50px] 
            sm:p-8 sm:ml-0 sm:mt-[-20px] sm:w-full  
            md:ml-0
            lg:mx-auto lg:ml-10
            2xxl:ml-[50px]
            ' >
            <p className='font-quatror text-[31px]  text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>They were blessed with an abundance of water, perfect soil for crops and were surrounded by mines rich in Diamonds.</p>
            <p className='font-quatror text-[31px]  text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>For 600 years the Rasnians have learned to be as one with the land, built opulent housing near to the waterfront and at the foot of the great mountains, known as the Motashu Mountains. The city was surrounded by walls to keep the dangers of those seeking the precious diamonds out. The locals both respected and feared the forest on the other side of the mountains and never dared venture into them, as there were many accounts of the early Rasnians going missing when exploring.  </p>
            </div>

      </div>
     
    </div>
     <div className='relative bg-none h-auto w-full'>
        <div className='w-[1420px] ml-[410px] 
          sm:w-full sm:h-auto sm:ml-0 sm:p-8
          md:w-full md:p-[100px] md:ml-5
          lg:p-[100px] lg:w-full lg:ml-0
          xl:p-22 xl:w-[1100px]  xl:ml-[200px]
          xl-2:p-22 xl-2:w-[1250px]  xl-2:ml-[200px]
          2xl:p-[90px] 2xl:w-auto 2xl:ml-[320px] 
          2xxl:p-[90px] 2xxl:w-auto 2xxl:ml-[345px] 
        '>
        <p className='font-quatror text-[31px]  text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>
                  The local ape population, known as the Konchima, have always been friendly to the Rasnians, share the common land and dare never venture into the forest.  </p>
          <p className='font-quatror text-[31px]  text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>The pride of the Rasnians collection and wealth was the Apremort Diamond weighing in at 5000 carats and 2 pounds in weight. It has stood as a monument in the middle of the city for 200 years and the locals believe it is a great source of power that can potentially allow spirits to fly. There is almost no time at day or night that you won’t see someone praying to the Diamond and asking for safe passage between this world to the next. Those that are caught between the worlds are thought to take the shape of Gargoyles and will haunt the forest for all of eternity, trapped in the depth of its thick trees and unable to pass to the next world.  
          </p>  
        </div>
    </div> 
    <div className='relative bg-the-lore-bg-continue bg-contain bg-no-repeat bg-center h-auto w-full'>
      <div className='p-20 sm:p-0 md:p-20 '>
      <div className='w-auto  
          sm:w-auto sm:p-5 
          md:w-auto  md:ml-0 
          lg:w-auto
          xl:w-[600px] xl:mr-[-30px] xl:ml-auto 
          2xl:w-[400px]2xl:mr-[-30px] 2xl:ml-auto 
          2xxl:w-[800px] ml-auto p-2 '>
        <p className='font-quatror text-[31px] sm:p-2 text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>The Rasnians have always believed in curses and spells and believe the world strikes a balance to grant them great wealth, by providing fewer females to continue their bloodlines and a high rate of infant mortality for the women that are born in each hundred babies.  </p>
        <p className='font-quatror text-[31px] sm:p-2 text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>Women are considered sacred, and they are known to be healers. The older healers have the power to use magic, and many seek their help to prey for those near death, in the hope they can also help their loved ones pass to the new world with ease. However, some of the Rasnians believe they are not aiding and rather causing their loved ones to be stuck as Gargoyles in the forest and an uprising against certain healers began to form, where they were accused of being witches. In time the animosity became so great that the older healers began to fear for their lives and hated the rest of the Rasnians so much they fled for the forest one night in a small group, taking with them the Apremort Diamond. </p>
        <p className='font-quatror text-[31px] sm:p-2 text-game-text-color sm:text-[16px] sm:mt-2 text-justify mt-10 md:text-[30px]'>Now the Rasnians must muster the power to go deep into the forest and retrieve their precious diamond before its too late.  </p>
      </div>
        
      </div>
    </div>
    <div className='mt-0'>
    <Divider/>
    </div>
    </section>
  )
}

export default Lore