import React, { useState, useEffect } from "react";
import "../Timer/timer.css"
import TimerConfig from "../../assets/timer.json"
import Paragraph from '../Paragraph'
import { Link } from "react-router-dom";
function TimerMini() {
    const [isMint, setMint] = useState(false)
    const [expiryTime, setExpiryTime] = useState(TimerConfig.expiration);
    const [isExpired,setExpired] = useState(false)
    const [countdownTime, setCountdownTime]= useState(
        {
            countdownDays:'',
            countdownHours:'',
            countdownlMinutes:'',
            countdownSeconds:''
        }
    );
     const countdownTimer=()=>{
     
           const timeInterval= setInterval(() => {
           const countdownDateTime = new Date(expiryTime).getTime(); 
           const currentTime = new Date().getTime();
           const remainingDayTime = countdownDateTime - currentTime;
           const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
           const totalHours = Math.floor((remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
           const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
           const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
      
           const runningCountdownTime={
              countdownDays: totalDays,
              countdownHours: totalHours,
              countdownMinutes: totalMinutes,
              countdownSeconds: totalSeconds
           }
        
           setCountdownTime(runningCountdownTime);
      
           if (remainingDayTime < 0) {
              clearInterval(timeInterval);
              setExpiryTime(false);
              setExpired(true)
             }
      
          }, 1000);
     }
      
     useEffect(() => {
         countdownTimer();
     });

    
  return (

    <> 


     <div className='font-quatror text-white text-[16px]'>
        {
            isExpired !== false ? 'Event ended!' : <span> Ending in {countdownTime.countdownDays} d {countdownTime.countdownHours}h {countdownTime.countdownMinutes}m {countdownTime.countdownSeconds}s</span>
        }
     
    </div>
    </>
   
    
  )
}

export default TimerMini