import React from 'react'

function FaqContainer({question,answer}) {
  return (
    <div className=''>
       <p className='font-quatrob text-[25px] md:text-[31px]  text-white'>{question}</p>
       <p className='font-quatror text-[16px] md:text-[21px] text-yellow-light text-justify mb-10'>{answer}</p>
    </div>
  )
}

export default FaqContainer