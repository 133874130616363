import React  from 'react'
import { Routes, Route } from 'react-router-dom';
import HomePage from '../src/Homepage';
import MintPage from '../src/components/MintPage/MintPage'


function App() {

  return (
    <>
    <Routes>
    <Route path="/" element={ <HomePage/> } />
    <Route path="mint" element={ <MintPage/> } smooth />
    </Routes>    
    </>
   
  );
}

export default App;
