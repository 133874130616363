import React from 'react'
import FaqHeaderMobile from "../assets/images/mobile_header_faq.png"
import Divider from './Divider'
import FaqContainer from './Faqs/FaqContainer'
import HeaderImage from './HeaderImage'
function Faqs() {
  return (
    <>
    <section id='faqs'>
     <Divider /> 
    <div className='bg-none h-auto w-full'>

        <HeaderImage 
        imageSource={FaqHeaderMobile} 
        dividerStyle='w-full'
        imageStyle='mt-[100px] 
        sm:mt-[0px]
        mx:0 md:mx-auto 
        xl:mx-0 
        '
        />

        <div className='p-20 sm:hidden  md:block md:p-20  lg:p-20'>
        <div className='block'>
                <div className='p-20 grid grid-cols-2 gap-12 lg:gap-36'>
                        <div>
                            <FaqContainer 
                            question={'Is it free to play?'}
                            answer={'Yes, you can experience RaSNIA:FEAR for free however there are also in-game purchases, top ups, and NFTs'}
                            />
                             <FaqContainer 
                            question={'How to get started?'}
                            answer={'The game will be available as part of the RFOX VALT download once the game is released in November '}
                            />
                            <FaqContainer 
                            question={'How to mint?'}
                            answer="Mint details will be shared on our official Twitter channel, 
                            and will take place on the 27th of September, beware of scams or anything that doesn't come from our official channels"
                            />

                            <FaqContainer 
                            question={' Where can I buy the NFTs and top-ups for the game?'}
                            answer="You can purchase the NFTs on the secondary market on Opensea if you miss the mint. Top-ups and other NFTs will be purchasable in the RaSNIA:FEAR game lobby in the RFOX VALT."
                            />

                            <FaqContainer 
                            question={'How to play?'}
                            answer="There will be 2 modes. Explore mode and Escape mode. Explore is accessible by anyone. Tour the castle on your own. No rewards but plenty of scares. Escape is a multiplayer game mode only accessible by RaSNIA:FEAR NFT holders. Find the treasure hidden in the castle then escape before time runs out or another player steals your loot. Treasure can be NFTs, Tokens or game consumables."
                            />

                            <FaqContainer 
                            question={'What does the NFT do?'}
                            answer="The NFT allows you to participate in games, tournaments, and compete for prizes."
                            />

                        </div>

                        <div>
                            <FaqContainer 
                            question={'How does the game generate revenue?'}
                            answer=" The game generates revenue through top ups, additional lives, crafting, and value adds. The game is one part of the RaSNIA:FEAR world."
                            />

                            <FaqContainer 
                            question={'Will it playable on mobile or PC eventually?'}
                            answer=" It will be playable on mobile and PC soon after its release."
                            />

                            <FaqContainer 
                            question={'Would I be able to sell my RaSNIA:FEAR NFTs?'}
                            answer="Yes. The RaSNIA:FEAR NFT belongs to you."
                            />

                            <FaqContainer 
                            question={'What IP rights do I get with my NFT?'}
                            answer="By owning a RaSNIA:FEAR NFT, you own the digital art or image attached to the NFT in its full form whether 2D or 3D (Art). This means that you own the full intellectual property rights to the Art, which entitles you to either use the Art for personal or commercial benefits."
                            />

                            <FaqContainer 
                            question={'What blockchain will the NFTs be on?'}
                            answer="Ethereum. It's an ERC721A."
                            />

                            <FaqContainer 
                            question={'Are you affiliated with RFOX, SAND, MANA, and Illuvium?'}
                            answer="No, we are not affiliated in any way to these projects."
                            />
                        </div>
                </div>
            
          </div>
        </div>
          <div className='w-full h-auto p-20 mb-20 hidden sm:p-10 sm:block md:hidden'>
                            <FaqContainer 
                            question={'Is it free to play?'}
                            answer={'Yes, you can experience RaSNIA:FEAR for free however there are also in-game purchases, top ups, and NFTs'}
                            />
                             <FaqContainer 
                            question={'How to get started?'}
                            answer={'The game will be available as part of the RFOX VALT download once the game is released in November '}
                            />
                            <FaqContainer 
                            question={'How to mint?'}
                            answer="Mint details will be shared on our official Twitter channel, 
                            and will take place on the 27th of September, beware of scams or anything that doesn't come from our official channels"
                            />

                            <FaqContainer 
                            question={' Where can I buy the NFTs and top-ups for the game?'}
                            answer="You can purchase the NFTs on the secondary market on Opensea if you miss the mint. Top-ups and other NFTs will be purchasable in the RaSNIA:FEAR game lobby in the RFOX VALT."
                            />

                            <FaqContainer 
                            question={'How to play?'}
                            answer="There will be 2 modes. Explore mode and Escape mode. Explore is accessible by anyone. Tour the castle on your own. No rewards but plenty of scares. Escape is a multiplayer game mode only accessible by RaSNIA:FEAR NFT holders. Find the treasure hidden in the castle then escape before time runs out or another player steals your loot. Treasure can be NFTs, Tokens or game consumables."
                            />

                            <FaqContainer 
                            question={'What does the NFT do?'}
                            answer="The NFT allows you to participate in games, tournaments, and compete for prizes."
                            />

                      
                            <FaqContainer 
                            question={'How does the game generate revenue?'}
                            answer=" The game generates revenue through top ups, additional lives, crafting, and value adds. The game is one part of the RaSNIA:FEAR world."
                            />

                            <FaqContainer 
                            question={'Will it playable on mobile or PC eventually?'}
                            answer=" It will be playable on mobile and PC soon after its release."
                            />

                            <FaqContainer 
                            question={'Would I be able to sell my RaSNIA:FEAR NFTs?'}
                            answer="Yes. The RaSNIA:FEAR NFT belongs to you."
                            />

                            <FaqContainer 
                            question={'What IP rights do I get with my NFT?'}
                            answer="By owning a RaSNIA:FEAR NFT, you own the digital art or image attached to the NFT in its full form whether 2D or 3D (Art). This means that you own the full intellectual property rights to the Art, which entitles you to either use the Art for personal or commercial benefits."
                            />

                            <FaqContainer 
                            question={'What blockchain will the NFTs be on?'}
                            answer="Ethereum. It's an ERC721A."
                            />

                            <FaqContainer 
                            question={'Are you affiliated with RFOX, SAND, MANA, and Illuvium?'}
                            answer="No, we are not affiliated in any way to these projects."
                            />
                        
    </div>
    </div>
    </section>
 
  

    </>
   
    

  )
}

export default Faqs