import React from 'react'
import DividerImage from "../assets/images/divider.jpeg"
function Divider() {
  return (
    <div className={'content-center object-center px-[250px] m-5 sm:px-10 md:px-[10px] xl:px-[150px] 2xl:px-[170px] 2xxl:px-[160px]'}>
        <img src={DividerImage} className='w-full' />    
    </div>
  )
}

export default Divider