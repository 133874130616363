import React from 'react'
import "../Divider/divider.css"
import DividerImage from "../../assets/images/divider.png"
function Divider() {
  return (
    <div className='content-center object-center divider_container'>
       <img src={DividerImage} className='divider_img' />
    </div>
  )
}

export default Divider